import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";
import {
  ModalHeader,
  RotaButton,
  RotaButtonIcon,
  RotaCheckbox,
  RotaChip,
  RotaDropdown,
  RotaNewModal,
  RotaSwitchWithLabel,
  iconNew,
  useToast
} from "@teamrota/rota-design";

import { SHIFT_TYPES } from "~/src/consts";
import { useHasBreaksConfig } from "~/src/graphql/queries/use-has-breaks-config/use-has-breaks-config";
import Select from "~/src/components/form-components/select";
import TextInput from "~/src/components/form-components/text-input";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import DateTimePicker from "./DateTimePicker";

import useVenues from "./hooks/use-venues";
import useRoles from "./hooks/use-roles";
import useMembers from "./hooks/use-members";
import { useCreateQuickAddTimesheet } from "./hooks/use-create-timesheet";
import { validate } from "./validate";
import { Props, State, memberType } from "./types";
import { formatData, makeOptions } from "./helpers";

import {
  ButtonContainer,
  CheckboxRow,
  ChipsRow,
  DatepickerRow,
  EmptySpace,
  InputsRow,
  ModalContentWrapper,
  Separator
} from "./styles";

const { Sun, Moon, Cancel } = iconNew;

const TimesheetQuickAddModal: React.FC<Props> = ({ onClose, user }) => {
  const initialTime = moment()
    .seconds(0)
    .milliseconds(0)
    .subtract(2, "days");

  const initialState: State = {
    venueId: "",
    subvenueId: "",
    roleRateId: "",
    shiftName: "",
    shiftType: SHIFT_TYPES.DAY,
    scheduledStartTime: initialTime,
    scheduledEndTime: initialTime,
    scheduledBreakMinutes: 0,
    isDifferentCheckInTime: false,
    checkInTime: initialTime,
    checkOutTime: initialTime,
    isSleepTime: false,
    sleepStartTime: initialTime,
    sleepEndTime: initialTime,
    actualSleepStartTime: initialTime,
    actualSleepEndTime: initialTime,
    actualBreakMinutes: null,
    isDisturbedHours: false,
    disturbedHoursStart: initialTime,
    disturbedHoursEnd: initialTime,
    autoApprove: false
  };

  const [formState, setFormState] = useState<State>(initialState);
  const [selectedMembers, setSelectedMembers] = useState<memberType[]>([]);
  const [searchName, _setSearchName] = useState("");
  const setSearchName = debounce(_setSearchName, 300);

  const { venues } = useVenues();
  const { roles } = useRoles();

  const { members, isLoading, onLoadMore, onFilter } = useMembers({
    targetAccountId: user.account.requesterConnections.data[0].sourceAccount.id,
    selectedAccountId: user.account.id,
    venueId: formState.venueId,
    roleRateId: formState.roleRateId
  });

  const hasBreaksConfig = useHasBreaksConfig({
    roleRateId: formState.roleRateId,
    venueId: formState.venueId
  });

  const subvenues =
    venues.find(venue => venue.id === formState.venueId)?.subvenues || [];

  const updateForm = <K extends keyof State>(
    field: K,
    value: State[K] | null
  ) => {
    setFormState(prevState => ({ ...prevState, [field]: value }));
  };

  const handleSelectMember = (memberId: string) => {
    const member = {
      id: memberId,
      name: members.find((member: any) => member.id === memberId)?.name
    };

    const isMemberSelected = selectedMembers.some(
      member => member.id === memberId
    );

    if (isMemberSelected) {
      setSelectedMembers(prevState =>
        prevState.filter(member => member.id !== memberId)
      );
    } else {
      setSelectedMembers(prevState => [...prevState, member]);
    }
  };

  const formErrors = validate({ ...formState, selectedMembers });

  useEffect(() => {
    onFilter(searchName);
  }, [searchName]);

  const { showToast } = useToast();

  const onCompleted = () => {
    showToast("Timesheet created successfully");
    onClose();
  };

  const { createQuickAddTimesheet } = useCreateQuickAddTimesheet({
    onCompleted
  });

  const onSave = () => {
    const variables = formatData(formState, selectedMembers);
    createQuickAddTimesheet(variables);
  };

  const handleStartDateSelect = (value: moment.Moment) => {
    updateForm("scheduledStartTime", value);

    const isDifferentDay = !formState.scheduledStartTime.isSame(value, "day");

    if (isDifferentDay) {
      const updateOnlyDate = (field: string, value: moment.Moment) => {
        const oldTime = formState[field as keyof State];
        const newTime = moment(oldTime as moment.MomentInput)
          .clone()
          .set({
            year: value.year(),
            month: value.month(),
            date: value.date()
          });

        updateForm(field as keyof State, newTime);
      };

      updateOnlyDate("scheduledEndTime", value);
      updateOnlyDate("checkInTime", value);
      updateOnlyDate("checkOutTime", value);
      updateOnlyDate("sleepStartTime", value);
      updateOnlyDate("sleepEndTime", value);
      updateOnlyDate("actualSleepStartTime", value);
      updateOnlyDate("actualSleepEndTime", value);
      updateOnlyDate("disturbedHoursStart", value);
      updateOnlyDate("disturbedHoursEnd", value);
    }
  };

  return (
    <RotaNewModal
      onClose={onClose}
      size="medium"
      header={
        <ModalHeader
          title={"Quick Add Timesheet"}
          endAction={
            <RotaButtonIcon onClick={onClose}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
      footer={
        <ButtonContainer>
          <RotaButton
            disabled={Object.keys(formErrors).length > 0}
            onClick={onSave}
          >
            Save
          </RotaButton>
        </ButtonContainer>
      }
    >
      <ModalContentWrapper>
        <InputsRow>
          <Select
            label="Role"
            options={makeOptions(roles)}
            placeholder="Select a role"
            isLoading={false}
            isDisabled={false}
            value={formState.roleRateId}
            onChange={(value: string) => {
              updateForm("roleRateId", value);
              setSelectedMembers([]);
            }}
          />

          {/* @ts-expect-error TS-2740 */}
          <TextInput
            label="Event name"
            isError={false}
            value={formState.shiftName}
            onChangeValue={(value: string): void =>
              updateForm("shiftName", value)
            }
            placeholder={"Enter event name"}
          />
        </InputsRow>

        <InputsRow>
          <Select
            label="Venue"
            options={makeOptions(venues)}
            placeholder="Select venue"
            isLoading={false}
            isDisabled={false}
            value={formState.venueId}
            onChange={(value: string) => {
              updateForm("venueId", value);
              updateForm("subvenueId", "");
            }}
          />

          <Select
            label="Subvenue"
            options={makeOptions(subvenues)}
            placeholder="Select subvenue"
            isLoading={false}
            isDisabled={false}
            value={formState.subvenueId}
            onChange={(value: string) => updateForm("subvenueId", value)}
          />
        </InputsRow>

        <DatepickerRow>
          <DateTimePicker
            maxDate={new ZonedDate()}
            label="Shift start time"
            dateTime={formState.scheduledStartTime}
            setDateTime={handleStartDateSelect}
            isError={!!formErrors.scheduledStartTime}
            errorMessage={formErrors.scheduledStartTime}
          />

          <DateTimePicker
            maxDate={new ZonedDate()}
            label="Shift end time"
            dateTime={formState.scheduledEndTime}
            setDateTime={value => updateForm("scheduledEndTime", value)}
            isError={!!formErrors.scheduledEndTime}
            errorMessage={formErrors.scheduledEndTime}
          />
        </DatepickerRow>

        <InputsRow>
          <RotaSwitchWithLabel
            iconOn={<Sun />}
            iconOff={<Moon />}
            checked={formState.shiftType === SHIFT_TYPES.DAY}
            onChange={e =>
              updateForm(
                "shiftType",
                e.target.checked ? SHIFT_TYPES.DAY : SHIFT_TYPES.NIGHT
              )
            }
          >
            Select shift type
          </RotaSwitchWithLabel>

          {/* @ts-expect-error TS-2740 */}
          <TextInput
            label="Break minutes"
            isDisabled={hasBreaksConfig}
            isError={false}
            value={
              hasBreaksConfig ? "automatic" : formState.scheduledBreakMinutes
            }
            onChangeValue={(value: string): void => {
              if (!hasBreaksConfig) {
                updateForm(
                  "scheduledBreakMinutes",
                  value.length > 0 ? parseInt(value) : 0
                );
              }
            }}
            placeholder={"Enter break minutes"}
          />
        </InputsRow>

        <Separator extraMargin />

        <InputsRow>
          <RotaDropdown
            id="staff-name"
            width={"260px"}
            label={"Staff name"}
            isLabelTop
            isSearchable
            placeholder={"Select staff"}
            isDisabled={!formState.venueId || !formState.roleRateId}
            options={makeOptions(members, { sortByName: true })}
            isError={false}
            errorMessage={""}
            onChange={value => {
              handleSelectMember(value);
              setSearchName("");
            }}
            isLoading={isLoading}
            onLoadMore={debounce(onLoadMore, 500)}
            onSearchText={value => setSearchName(value)}
            isDisplayValueDisabled
          />
        </InputsRow>

        <ChipsRow>
          {selectedMembers.map(member => (
            <RotaChip
              key={member.id}
              chipText={member.name}
              onClose={() => handleSelectMember(member.id)}
            />
          ))}
        </ChipsRow>

        <Separator />

        <CheckboxRow>
          <RotaCheckbox
            isChecked={formState.isDifferentCheckInTime}
            onClick={() =>
              updateForm(
                "isDifferentCheckInTime",
                !formState.isDifferentCheckInTime
              )
            }
            label={"Different times for Check In/Out"}
          />
        </CheckboxRow>

        {formState.isDifferentCheckInTime && (
          <>
            <DatepickerRow>
              <DateTimePicker
                label="Check in time"
                dateTime={formState.checkInTime}
                setDateTime={value => updateForm("checkInTime", value)}
              />

              <DateTimePicker
                label="Check out time"
                dateTime={formState.checkOutTime}
                setDateTime={value => updateForm("checkOutTime", value)}
                isError={!!formErrors.checkOutTime}
                errorMessage={formErrors.checkOutTime}
              />
            </DatepickerRow>

            <InputsRow>
              <EmptySpace />

              {/* @ts-expect-error TS-2740 */}
              <TextInput
                label="Break minutes"
                isError={false}
                value={formState.actualBreakMinutes ?? ""}
                onChangeValue={(value: string): void => {
                  if (value?.length > 0 && !isNaN(parseInt(value))) {
                    updateForm("actualBreakMinutes", Number(value));
                  } else {
                    updateForm("actualBreakMinutes", null);
                  }
                }}
                placeholder={"Enter break minutes"}
              />
            </InputsRow>
          </>
        )}

        <Separator />

        <CheckboxRow>
          <RotaCheckbox
            isChecked={formState.isSleepTime}
            onClick={() => updateForm("isSleepTime", !formState.isSleepTime)}
            label={"Add sleep time"}
          />
        </CheckboxRow>

        {formState.isSleepTime && (
          <>
            <DatepickerRow>
              <DateTimePicker
                label="Scheduled start"
                dateTime={formState.sleepStartTime}
                setDateTime={value => updateForm("sleepStartTime", value)}
                isError={!!formErrors.sleepStartTime}
                errorMessage={formErrors.sleepStartTime}
              />
              <DateTimePicker
                label="Scheduled end"
                dateTime={formState.sleepEndTime}
                setDateTime={value => updateForm("sleepEndTime", value)}
                isError={!!formErrors.sleepEndTime}
                errorMessage={formErrors.sleepEndTime}
              />
            </DatepickerRow>

            <DatepickerRow>
              <DateTimePicker
                label="Actual start"
                dateTime={formState.actualSleepStartTime}
                setDateTime={value => updateForm("actualSleepStartTime", value)}
                isError={!!formErrors.actualSleepStartTime}
                errorMessage={formErrors.actualSleepStartTime}
              />

              <DateTimePicker
                label="Actual end"
                dateTime={formState.actualSleepEndTime}
                setDateTime={value => updateForm("actualSleepEndTime", value)}
                isError={!!formErrors.actualSleepEndTime}
                errorMessage={formErrors.actualSleepEndTime}
              />
            </DatepickerRow>

            <CheckboxRow>
              <RotaCheckbox
                isChecked={formState.isDisturbedHours}
                onClick={() =>
                  updateForm("isDisturbedHours", !formState.isDisturbedHours)
                }
                label={"Add disturbed hours"}
              />
            </CheckboxRow>

            {formState.isDisturbedHours && (
              <DatepickerRow>
                <DateTimePicker
                  label="Disturbed hours start"
                  dateTime={formState.disturbedHoursStart}
                  setDateTime={value =>
                    updateForm("disturbedHoursStart", value)
                  }
                  isError={!!formErrors.disturbedHoursStart}
                  errorMessage={formErrors.disturbedHoursStart}
                />

                <DateTimePicker
                  label="Disturbed hours end"
                  dateTime={formState.disturbedHoursEnd}
                  setDateTime={value => updateForm("disturbedHoursEnd", value)}
                  isError={!!formErrors.disturbedHoursEnd}
                  errorMessage={formErrors.disturbedHoursEnd}
                />
              </DatepickerRow>
            )}
          </>
        )}

        <Separator />

        <CheckboxRow>
          <RotaCheckbox
            isChecked={formState.autoApprove}
            onClick={() => updateForm("autoApprove", !formState.autoApprove)}
            label={"Auto approve this timesheet"}
          />
        </CheckboxRow>
      </ModalContentWrapper>
    </RotaNewModal>
  );
};

export default getProfile(TimesheetQuickAddModal);
